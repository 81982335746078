import React from 'react';
import { Route, Routes } from 'react-router';
import AccommPage from './AccommPage';
import SearchPage from './SearchPage';
import HomePage from './HomePage';
import { useSelector } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import HotelsIndex from './hotels';

function Index() {
    const fetching = useSelector((state) => state.accommodations.fetching);
    return (
        <>
            <Routes path="/">
                <Route path="offers/*">
                    <Route index exact element={<HomePage />} />
                    <Route path="search" exact element={<SearchPage />} />
                    <Route path="search/:id" exact element={<AccommPage />} />
                </Route>
                <Route path="hotels/*" exact element={<HotelsIndex />} />
                <Route path="*" element={<div>Not found</div>} />
            </Routes>
            <Dimmer active={fetching} page inverted>
                <Loader></Loader>
            </Dimmer>
        </>
    );
}

export default Index;
