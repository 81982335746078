import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/RoomsPicker2.module.css';
import { MdOutlineAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';

// This room picker repeats the same room data for each room

export const DefaultRoomData = {
    adult: 2,
    child: 0,
    childAges: [],
};

//NOTE: Workaround nested object deep clone issue, by override childAges
export const DefaultRoomsData = [{ ...DefaultRoomData, childAges: [] }];

export const RoomsValidate = (rooms) => {
    for (let i = 0; i < rooms?.length; i++) {
        const room = rooms[i];
        const error = RoomValidate(room);
        if (!!error) {
            return error;
        }
    }
    return '';
};

const RoomValidate = (room) => {
    const ageLength = room?.childAges?.length ?? 0;
    if (room.child != ageLength) {
        return '请填写儿童年龄';
    }
};

// PAX needs to be same accross all rooms
function RoomsPicker2({ rooms = DefaultRoomsData, setRooms = () => {} }) {
    const handleAdultAdd = () => {
        for (let i = 0; i < rooms.length; i++) {
            rooms[i].adult++;
        }
        setRooms([...rooms]);
    };

    const handleAdultSub = () => {
        for (let i = 0; i < rooms.length; i++) {
            rooms[i].adult--;
            if (rooms[i].adult <= 1) {
                rooms[i].adult = 1;
            }
        }
        setRooms([...rooms]);
    };

    const handleChildAdd = () => {
        for (let i = 0; i < rooms.length; i++) {
            rooms[i].child++;
        }
        setRooms([...rooms]);
    };

    const handleChildSub = () => {
        for (let i = 0; i < rooms.length; i++) {
            const room = rooms[i];
            if (room.childAges?.length === room.child) {
                room.childAges.pop();
            }
            room.child--;
            if (room.child <= 0) {
                room.child = 0;
            }
        }
        setRooms([...rooms]);
    };

    const handleRoomAdd = () => {
        setRooms([...rooms, rooms[0]]);
    };

    const handleRoomRemove = () => {
        if (rooms.length > 1) {
            rooms.pop();
            setRooms([...rooms]);
        }
    };

    const handleOnChangeAge = (e, index) => {
        for (let i = 0; i < rooms.length; i++) {
            const room = rooms[i];
            const age = e.target.value;
            if (room.childAges) {
                room.childAges[index] = age;
            } else {
                room.childAges = [age];
            }
            room.childAges = room.childAges.filter((item) => item != '');
        }
        console.log(rooms);
        setRooms([...rooms]);
    };

    return (
        <div className={styles.container}>
            <div className={styles.room_container}>
                <div className={styles.room_index}>房间</div>
                <div className={styles.adult_container}>
                    <div>成人</div>
                    <div className={styles.controller_container}>
                        <MdRemoveCircleOutline className={styles.icon} onClick={handleAdultSub} />
                        <span className={styles.number}>{rooms[0].adult}</span>
                        <MdOutlineAddCircleOutline className={styles.icon} onClick={handleAdultAdd} />
                    </div>
                </div>
                <div className={styles.child_container}>
                    <span>儿童(0到17岁)</span>
                    <div className={styles.controller_container}>
                        <MdRemoveCircleOutline className={styles.icon} onClick={handleChildSub} />
                        <span className={styles.number}>{rooms[0].child}</span>
                        <MdOutlineAddCircleOutline className={styles.icon} onClick={handleChildAdd} />
                    </div>
                </div>
                {rooms[0].child > 0 && (
                    <div className={styles.child_ages_container}>
                        {[...Array(rooms[0].child)].map((_, index) => {
                            return (
                                <select
                                    key={index}
                                    className={styles.child_age_container}
                                    onChange={(e) => handleOnChangeAge(e, index)}
                                >
                                    <option value="">儿童{index + 1} 年龄</option>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((item, jndex) => {
                                        return (
                                            <option key={jndex} value={item}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </select>
                            );
                        })}
                    </div>
                )}
                {RoomValidate(rooms[0]) && <div style={{ color: 'tomato' }}>{RoomValidate(rooms[0])}</div>}
                <div className={styles.divider}></div>
                <div className={styles.counter_container}>
                    <div>房间数</div>
                    <div className={styles.controller_container}>
                        <MdRemoveCircleOutline className={styles.icon} onClick={handleRoomRemove} />
                        <span className={styles.number}>{rooms.length}</span>
                        <MdOutlineAddCircleOutline className={styles.icon} onClick={handleRoomAdd} />
                    </div>
                </div>
            </div>
        </div>
    );
}

RoomsPicker2.propTypes = {
    rooms: PropTypes.arrayOf(
        PropTypes.shape({
            adult: PropTypes.number,
            child: PropTypes.number,
            childAges: PropTypes.arrayOf(PropTypes.string),
        })
    ),
    setRooms: PropTypes.func.isRequired,
};

export default RoomsPicker2;
