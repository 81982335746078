import { useCallback, useState, useRef } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useHotelOrderBooking() {
    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);
    //response data
    const [data, setData] = useState({});
    // AbortController ref
    const abortControllerRef = useRef(null);

    const fetchOrderBooking = useCallback(async (orderID) => {
        abortControllerRef.current = new AbortController();
        setFetching(true);
        setFetched(false);
        try {
            const res = await fetch(`${config.apiURL}/sabre/hotel/orders/${orderID}`, {
                signal: abortControllerRef.current.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            });
            if (!res.ok) {
                const data = await res.json();
                throw new Error(data.error || 'An error occurred while fetching the order');
            }
            const data = await res.json();
            setData(data);
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetching order:', error);
            }
            throw error;
        } finally {
            setFetching(false);
            setFetched(true);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { fetching, fetched, data, fetchOrderBooking, abort };
}

export default useHotelOrderBooking;
