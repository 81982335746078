import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { register } from 'swiper/element/bundle';
register();
import '@styles/swiper.css';

function PhotoSwiper({ urls = [], initIndex = 0 }) {
    const swiperElRef = useRef(null);

    useEffect(() => {
        console.log('111');
        // listen for Swiper events using addEventListener
        const onSwiperprogress = (e) => {
            const [swiper, progress] = e.detail;
            // console.log(progress);
        };
        const onSwiperslidechange = (e) => {
            console.log('slide changed');
        };
        swiperElRef.current.addEventListener('swiperprogress', onSwiperprogress);
        swiperElRef.current.addEventListener('swiperslidechange', onSwiperslidechange);

        return () => {
            swiperElRef.current?.removeEventListener('swiperprogress', onSwiperprogress);
            swiperElRef.current?.removeEventListener('swiperslidechange', onSwiperslidechange);
        };
    }, []);

    return (
        <div>
            <swiper-container
                ref={swiperElRef}
                initial-slide={initIndex}
                navigation="true"
                pagination="true"
                pagination-clickable="true"
            >
                {urls.map((item, index) => {
                    // console.log(`${config.baseURL}/public/${item.filename}`);
                    return (
                        <swiper-slide key={index}>
                            <img src={item} alt="" />
                        </swiper-slide>
                    );
                })}
            </swiper-container>
        </div>
    );
}

PhotoSwiper.propTypes = {
    urls: PropTypes.arrayOf(PropTypes.string),
    initIndex: PropTypes.number,
};

export default PhotoSwiper;
