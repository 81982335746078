import React from 'react';
import PropTypes from 'prop-types';
import useHotelOrderPatch from '@hooks/hotel/useHotelOrderPatch';
import { useState, useEffect } from 'react';
import { App, Button, Input, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { fetchHotelOrders } from '@features/hotelorders/ordersSlice';
import { useDispatch } from 'react-redux';

function UserDefinedEdit({ userDefined, orderID }) {
    const dispatch = useDispatch();
    const { submitting, handleOrderPatch, abort } = useHotelOrderPatch();
    const [open, setOpen] = useState(false);
    const [newUserDefined, setNewUserDefined] = useState();
    const { message } = App.useApp();

    useEffect(() => {
        if (open) {
            setNewUserDefined(userDefined);
        } else {
            setNewUserDefined();
        }
        return () => {
            abort();
        };
    }, [userDefined, open]);

    const handleSubmit = () => {
        console.log(newUserDefined);
        const req = {
            userDefined: newUserDefined,
        };
        handleOrderPatch(orderID, req)
            .then((data) => {
                setOpen(false);
                message?.success(data.reply);
                dispatch(fetchHotelOrders());
            })
            .catch((error) => {
                console.error(error);
                message?.error(error.message);
            });
    };

    return (
        <div>
            <span>{userDefined.slice(0, 8)}</span>
            <Button type="link" size="small" onClick={() => setOpen((prev) => !prev)} icon={<EditOutlined />} />
            <Modal
                open={open}
                onCancel={() => setOpen(false)}
                centered
                okText="Update"
                onOk={handleSubmit}
                okButtonProps={{ loading: submitting }}
                cancelButtonProps={{ disabled: submitting }}
            >
                <h3>User Defined Name</h3>
                <Input
                    placeholder="User Defined Name"
                    value={newUserDefined}
                    onChange={(e) => setNewUserDefined(e.target.value)}
                    autoFocus={true}
                    autoComplete="new-password"
                />
            </Modal>
        </div>
    );
}

UserDefinedEdit.propTypes = {
    orderID: PropTypes.string.isRequired,
    userDefined: PropTypes.string,
};

export default React.memo(UserDefinedEdit);
