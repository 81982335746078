import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Space, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHotelOrders, defaultQueryReq } from '@features/hotelorders/ordersSlice';
import { useNavigate } from 'react-router';
import useHotelOrderPut from '@hooks/hotel/useHotelOrderPut';
import UserDefinedEdit from './UserDefinedEdit';

function HotelOrdersTable() {
    const dispatch = useDispatch();
    const { data, total, fetching } = useSelector((state) => state.hotelOrders);
    const [req, setReq] = useState(defaultQueryReq);

    useEffect(() => {
        dispatch(fetchHotelOrders(req));
    }, [req]);

    const Actions = ({ orderID }) => {
        const navigate = useNavigate();
        const { submitting, handleOrderPut, abort } = useHotelOrderPut();

        useEffect(() => {
            return () => {
                abort();
            };
        }, []);

        const handleRefresh = () => {
            handleOrderPut(orderID)
                .then((res) => {
                    dispatch(fetchHotelOrders());
                })
                .catch((err) => {
                    let msg = err.toString();
                    if (err.response?.data?.error) {
                        console.log('***', err.response.data.error);
                        msg = err.response.data.error;
                    }
                    window.alert(msg);
                });
        };

        const gotoOrderPage = () => {
            navigate(orderID);
        };

        return (
            <Space>
                <Button type="link" size="small" onClick={gotoOrderPage}>
                    查看
                </Button>
                <Button type="link" size="small" loading={submitting} onClick={handleRefresh}>
                    更新
                </Button>
            </Space>
        );
    };

    Actions.propTypes = {
        orderID: PropTypes.string.isRequired,
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'userDefined',
            render: (text, record) => <UserDefinedEdit userDefined={text} orderID={record.id} />,
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (text) => <div>{dayjs(text).format('YYYY-MM-DD')}</div>,
        },
        {
            title: 'Hotel',
            dataIndex: 'hotelName',
            render: (text) => <div>{text}</div>,
        },

        {
            title: 'Lead Guest',
            dataIndex: 'fullName',
        },
        {
            title: 'Check In',
            dataIndex: 'checkinDate',
        },
        {
            title: 'Check Out',
            dataIndex: 'checkoutDate',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            render: (total, { currencyCode }) => (
                <>
                    {currencyCode} {total}
                </>
            ),
        },
        {
            title: 'Commission',
            dataIndex: 'commission',
            render: (value, { currencyCode }) => (
                <>
                    {currencyCode} {value}
                </>
            ),
        },
        {
            title: 'Tag',
            dataIndex: 'cancelled',
            render: (cancelled, record) => {
                if (cancelled) {
                    return <Tag color="red">取消</Tag>;
                } else {
                    return <Tag color="green">{record.statusName}</Tag>;
                }
            },
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            render: (_, { id }) => <Actions orderID={id} />,
        },
    ];

    const handleTableChange = (newPagination, filters, sorter) => {
        //NOTE: leave filters, sorter for local sort
        setReq((prev) => {
            return {
                ...prev,
                page: newPagination.current,
                pageSize: newPagination.pageSize,
            };
        });
    };

    return (
        <div>
            <Space style={{ marginBottom: 8, display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="link" loading={fetching} onClick={() => dispatch(fetchHotelOrders())}>
                    刷新
                </Button>
            </Space>
            <Table
                columns={columns}
                rowKey={'id'}
                dataSource={data}
                bordered
                loading={fetching}
                pagination={{
                    current: req.page,
                    pageSize: req.pageSize,
                    total,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (num) => `Total ${num} items`,
                    pageSizeOptions: [5, 10, 20, 30],
                }}
                onChange={handleTableChange}
            />
        </div>
    );
}

HotelOrdersTable.propTypes = {};

export default HotelOrdersTable;
