import { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { GetToken } from '@utils/storage';
import * as config from '@config';

//Patch user defined name
function useHotelOrderPatch(props) {
    const [submitting, setSubmitting] = useState(false);
    const abortControllerRef = useRef(null);

    const handleOrderPatch = useCallback(async (id, req) => {
        setSubmitting(true);
        abortControllerRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL}/sabre/hotel/orders/${id}`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                },
                body: JSON.stringify(req),
                signal: abortControllerRef.current.signal,
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while updating the order');
            }
            return await response.json();
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error updating order:', error);
            }
            throw error;
        } finally {
            setSubmitting(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { submitting, handleOrderPatch, abort };
}

useHotelOrderPatch.propTypes = {};

export default useHotelOrderPatch;
