import { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { GetToken } from '@utils/storage';
import * as config from '@config';

// Sync order data with provider's data
// NOTE: This is a good reference we need to apply to other hooks
function useHotelOrderPut() {
    const [submitting, setSubmitting] = useState(false);
    // Ensures consistent access to the current AbortController across renders.
    // Prevents issues with stale closures.
    const abortControllerRef = useRef(null);

    const handleOrderPut = useCallback(async (id) => {
        setSubmitting(true);
        abortControllerRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL}/sabre/hotel/orders/${id}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                },
                signal: abortControllerRef.current.signal,
            });

            //Uses response.ok to check for successful responses, which covers all 2xx status codes.
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while updating the order');
            }
            return await response.json();
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error updating order:', error);
            }
            throw error;
        } finally {
            setSubmitting(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { submitting, handleOrderPut, abort };
}

useHotelOrderPut.propTypes = {};

export default useHotelOrderPut;
