import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/SearchLayout.module.css';
import { DestinationName } from '@utils/constants';
import AccommsGrid from './AccommsGrid';
import AccommsFilter from './AccommsFilter';
import { useSearchParams } from 'react-router-dom';

function Accommodations({ accommodations = [], callback = () => {} }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [title, setTitle] = useState('');
    const selectRef = useRef();

    useEffect(() => {
        const params = new URLSearchParams(searchParams);
        const selector = params.get('selector') || '';
        const sort = params.get('sort') || '';
        setTitle(DestinationName(selector));
        selectRef.current.value = sort;
        callback();
    }, [searchParams]);

    const handleOnChangeFilter = (e) => {
        const name = e.target.getAttribute('name');
        let value = e.target.getAttribute('value');
        // workaround select tag
        if (!!value == false) {
            value = e.target.value;
        }
        // console.log({ [name]: value });
        const params = new URLSearchParams(searchParams);
        switch (name) {
            case 'sort':
                if (value) {
                    params.set('sort', value);
                } else {
                    params.delete('sort');
                }
                setSearchParams(params);
            default:
                break;
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.top_container}>
                <div className={styles.title}>{title}</div>
                <div className={styles.sort_container}>
                    <select name="sort" ref={selectRef} onChange={handleOnChangeFilter}>
                        <option value="">默认排序</option>
                        <option value="price-desc">价格(从高到低)</option>
                        <option value="price-asc">价格(从低到高)</option>
                    </select>
                </div>
            </div>
            <div className={styles.bottom_container}>
                <section className={styles.leading_container}>
                    <AccommsFilter />
                </section>
                <section className={styles.trailing_container}>
                    <div className={styles.grid_container}>
                        <AccommsGrid accommodations={accommodations} />
                        {accommodations?.length < 1 && <div>暂无信息(资源更新可能出现排期问题，请联系客服)</div>}
                    </div>
                </section>
            </div>
        </div>
    );
}

Accommodations.propTypes = {
    accommodations: PropTypes.array.isRequired,
    callback: PropTypes.func.isRequired,
};

export default Accommodations;
