import { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as config from '@config';
import { GetToken } from '@utils/storage';

function useHotelVerify() {
    const [verifying, setVerifying] = useState(false);
    const [data, setData] = useState({});
    const [bookingKey, setBookingKey] = useState();
    const [totalCost, setTotalCost] = useState();

    const abortControllerRef = useRef(null);

    const priceVerify = async (rateKey) => {
        if (!rateKey) return;
        setVerifying(true);
        abortControllerRef.current = new AbortController();
        const req = {
            rateKey: rateKey,
        };
        try {
            const res = await axios.post(`${config.apiURL}/sabre/hotel/pricecheck`, req, {
                signal: abortControllerRef.current.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            });
            const data = res.data;
            console.log(data);
            setData(data);
            const bookingKey = data.bookingKey;
            if (bookingKey) {
                setBookingKey(bookingKey);
            }
            const totalCost = data.hotelRateInfo.rateInfos.convertedRateInfo[0].amountAfterTax;
            if (totalCost) {
                setTotalCost(totalCost);
            }
            return data;
        } catch (err) {
            let msg = err.toString();
            if (err.response?.data?.error) {
                console.log('***', err.response.data.error);
                msg = err.response.data.error;
            }
            window.alert(msg);
        } finally {
            setVerifying(false);
        }
    };

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { verifying, bookingKey, totalCost, data, priceVerify, abort };
}

useHotelVerify.propTypes = {};

export default useHotelVerify;
