import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/ImageSlides.module.css';

function ImageSlides({
    urls = [
        'https://picsum.photos/seed/this/1280/720.webp',
        'https://picsum.photos/seed/is/1280/720.webp',
        'https://picsum.photos/seed/a/1280/720.webp',
    ],
    initIndex = 0,
}) {
    const [activeIndex, setActiveIndex] = useState(initIndex);
    const prev = () => {
        setActiveIndex((prev) => {
            prev--;
            return (prev + urls.length) % urls.length;
        });
    };
    const next = () => {
        setActiveIndex((prev) => {
            prev++;
            return prev % urls.length;
        });
    };
    return (
        <div className={styles.container}>
            {urls.map((item, index) => {
                return (
                    <div key={item} className={`${styles.slide_container} ${index === activeIndex && styles.active}`}>
                        <img src={item} />
                    </div>
                );
            })}
            <a className={styles.prev} onClick={prev}>
                &#10094;
            </a>
            <a className={styles.next} onClick={next}>
                &#10095;
            </a>
        </div>
    );
}

ImageSlides.propTypes = {
    urls: PropTypes.arrayOf(PropTypes.string),
    initIndex: PropTypes.number,
};

export default ImageSlides;
