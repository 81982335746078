import { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as config from '@config';
import { GetToken } from '@utils/storage';

function useHotelDetail() {
    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false); //use to solve inital data issue
    const [hotelInfo, setHotelInfo] = useState({});
    const [hotelDescriptiveInfo, setHotelDescriptiveInfo] = useState({});
    const [rateInfos, setRateInfos] = useState([]);
    const [mediaItems, setMediaItems] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [imageURLs, setImageURLs] = useState([]);

    const abortControllerRef = useRef(null);

    const fetchHotelDetail = useCallback(async (req) => {
        console.log('fetchHotelDetail', req);
        setFetching(true);
        setFetched(false);
        abortControllerRef.current = new AbortController();
        try {
            const res = await axios.post(`${config.apiURL}/sabre/hotel/details`, req, {
                timeout: 60 * 1000,
                signal: abortControllerRef.current.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
                onDownloadProgress: (progressEvent) => {
                    const total = progressEvent.event.srcElement.getResponseHeader('content-length');
                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / total);
                    console.log('total:', total);
                    console.log(percentCompleted + '%');
                },
            });
            const data = res.data;
            // console.log(data);
            const hotelInfo = data.hotelInfo;
            if (hotelInfo) {
                setHotelInfo(hotelInfo);
            }
            const hotelDescriptiveInfo = data.hotelDescriptiveInfo;
            if (hotelDescriptiveInfo) {
                setHotelDescriptiveInfo(hotelDescriptiveInfo);
            }
            const rooms = data.hotelRateInfo?.rooms;
            if (rooms) {
                setRooms(rooms);
            }
            const rateInfos = data.hotelRateInfo.rateInfos.convertedRateInfos;
            if (rateInfos) {
                setRateInfos(rateInfos);
            }
            const mediaItems = data.mediaItems;
            if (mediaItems) {
                // console.log(mediaItems);
                setMediaItems(mediaItems);
                const urls = [];
                for (let i = 0; i < mediaItems.length; i++) {
                    const mItem = mediaItems[i];
                    for (let j = 0; j < mItem.imageItems.length; j++) {
                        const iItem = mItem.imageItems[j];
                        urls.push(iItem.url);
                    }
                }
                // console.log(urls);
                setImageURLs(urls);
            }
        } catch (error) {
            // Handle errors
            console.log(error);
            if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
                console.warn('Request timed out');
                alert('系统超时，请稍后再试');
            } else {
                let msg = error.toString();
                if (error.response?.data?.error) {
                    console.error('***', error.response.data.error);
                    msg = error.response.data.error;
                }
                console.error(msg);
            }
        } finally {
            setFetching(false);
            setFetched(true);
        }
    }, []);

    fetchHotelDetail.propTypes = {
        req: PropTypes.shape({
            RateKey: PropTypes.string,
            // for info search
            HotelCode: PropTypes.string,
            CodeContext: PropTypes.string,
            StartDate: PropTypes.string,
            EndDate: PropTypes.string,
            Rooms: PropTypes.array,
            CurrencyCode: PropTypes.string,
            RateSource: PropTypes.arrayOf(PropTypes.string), // 100,110,112,113
            RefundableOnly: PropTypes.string, //true or false
        }).isRequired,
    };

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return {
        fetching,
        fetched,
        hotelInfo,
        hotelDescriptiveInfo,
        rateInfos,
        mediaItems,
        rooms,
        imageURLs,
        fetchHotelDetail,
        abort,
    };
}

export default useHotelDetail;
