import React from 'react';
import * as config from '@config';
import { Link } from 'react-router-dom';
import styles from '@styles/TermsCondations.module.css';
import { IsThirdPartyDomain } from '../utils/domain';

function TermsCondations() {
    return (
        <>
            {IsThirdPartyDomain() == false && (
                <section className={styles.container}>
                    <div className={styles.faq_container}>
                        <Link to="/dashboard/faqs"> FAQ 提问解答</Link>
                    </div>
                    <div className={styles.document_container}>
                        <a
                            href={`${config.baseURL}/public/AO旅游免责声明和责任限制.txt?no-cache=${+new Date()}`}
                            target="_blank"
                        >
                            免责声明和责任限制
                        </a>
                    </div>
                    <div className={styles.document_container}>
                        <a
                            href={`${config.baseURL}/public/AO旅游服务条款和条件.txt?no-cache=${+new Date()}`}
                            target="_blank"
                        >
                            服务条款和条件
                        </a>
                    </div>
                </section>
            )}
        </>
    );
}

export default TermsCondations;
