import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import styles from '@styles/MyModal.module.css';
import { PreventBodyScroll } from '../utils/utils';

function MyModal({ fullscreen = false, active = false, setActive = () => {}, closeIcon = true, children }) {
    const [modelClasses, setModelClasses] = useState(styles.modal);

    useEffect(() => {
        // console.log('modal active changed', active);
        if (active) {
            openModal();
            PreventBodyScroll(true);
        } else {
            closeModal();
            PreventBodyScroll(false);
        }
        return () => {
            PreventBodyScroll(false);
        };
    }, [active]);

    const openModal = () => {
        setModelClasses(`${styles.modal} ${styles.modal_active}`);
        setActive(true);
    };

    const closeModal = () => {
        setModelClasses(styles.modal);
        setActive(false);
    };

    const handleBgClick = (e) => {
        const classes = e.target.className;
        // console.log(classes);
        if (typeof classes === 'string' && classes?.includes(styles.modal_active)) {
            closeModal();
        }
    };

    return (
        <aside className={modelClasses} onClick={handleBgClick}>
            <div className={`${styles.modal_content_container} ${fullscreen && styles.fullscreen}`}>
                {closeIcon && (
                    <div className={styles.close_badge} onClick={closeModal}>
                        <MdClose size="40px" />
                    </div>
                )}
                <div className={`${styles.modal_content} `}>{children}</div>
            </div>
        </aside>
    );
}

MyModal.propTypes = {
    active: PropTypes.bool.isRequired,
    setActive: PropTypes.func,
    closeIcon: PropTypes.bool,
    fullscreen: PropTypes.bool,
};

export default MyModal;
