import React from 'react';
import { Alert } from 'antd';
import * as config from '@config';

function AccommAlert() {
    return (
        <Alert
            message={
                <div>
                    ITO(团队酒店报价、预订)，请联系
                    <a href={`mailto:${config.SupportEmail}`}>{config.SupportEmail}</a>，我们会在48小时给与报价
                </div>
            }
            type="warning"
            showIcon
        />
    );
}

export default AccommAlert;
