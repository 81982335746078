import { useState, useRef, useCallback } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useHotelOrderCancel() {
    const [cancelling, setCancelling] = useState(false);
    const abortControllerRef = useRef(null);

    const handleOrderCancel = useCallback(async (orderID) => {
        abortControllerRef.current = new AbortController();
        // use Fetch API to cancel the order
        setCancelling(true);
        try {
            const response = await fetch(`${config.apiURL}/sabre/hotel/orders/cancel/${orderID}`, {
                method: 'POST',
                signal: abortControllerRef.current.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            });
            //Uses response.ok to check for successful responses, which covers all 2xx status codes.
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while updating the order');
            }
            return await response.json();
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error updating order:', error);
            }
            throw error;
        } finally {
            setCancelling(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { cancelling, handleOrderCancel, abort };
}

export default useHotelOrderCancel;
