import { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GetToken } from '@utils/storage';
import axios from 'axios';
import * as config from '@config';
import { GetHotelAvailData, SaveHotelAvailData } from '@utils/storage';

function useHotelAvail() {
    const [hotelAvailInfo, setHotelAvailInfo] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);

    const abortControllerRef = useRef(null);

    useEffect(() => {
        const data = GetHotelAvailData();
        if (Object.keys(data).length > 0) {
            setHotelAvailInfo(data);
        }
    }, []);

    const fetchHotelAvail = useCallback(async (req) => {
        console.log(req);
        setFetched(false);
        setFetching(true);
        abortControllerRef.current = new AbortController();
        try {
            const res = await axios.post(`${config.apiURL}/sabre/hotel/avail`, req, {
                timeout: 60 * 1000, //45 seconds
                signal: abortControllerRef.current.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
                onDownloadProgress: (progressEvent) => {
                    const total = progressEvent.event.srcElement.getResponseHeader('content-length');
                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / total);
                    console.log('total:', total);
                    console.log(percentCompleted + '%');
                },
            });
            const data = res.data;
            console.log(data);
            const hotelAvailInfo = data.hotelAvailInfo;
            if (hotelAvailInfo) {
                setHotelAvailInfo(hotelAvailInfo);
                SaveHotelAvailData(hotelAvailInfo);
            }
        } catch (error) {
            // Handle errors
            console.log(error);
            if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
                console.log('Request timed out');
                alert('系统超时，请稍后再试');
            } else {
                let msg = error.toString();
                if (error.response?.data?.error) {
                    console.log('***', error.response.data.error);
                    msg = error.response.data.error;
                }
                console.error(msg);
            }
        } finally {
            setFetching(false);
            setFetched(true);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { fetching, fetched, hotelAvailInfo, fetchHotelAvail, abort };
}

useHotelAvail.propTypes = {};

export default useHotelAvail;
