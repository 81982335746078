import { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as config from '@config';
import { GetToken } from '@utils/storage';

function useHotelMedia({ hotelCode, codeContext }) {
    const [fetching, setFetching] = useState(false);
    const [mediaItems, setMediaItems] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);

    const abortControllerRef = useRef(null);

    const fetchData = useCallback(async () => {
        setFetching(true);
        abortControllerRef.current = new AbortController();
        //Passed from props
        const req = {
            hotelCode: hotelCode,
            codeContext: codeContext,
        };
        try {
            const res = await axios.post(`${config.apiURL}/sabre/hotel/media`, req, {
                signal: abortControllerRef.current.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            });
            if (res.data) {
                const mediaItems = res.data.mediaItems;
                // console.log(mediaItems);
                if (mediaItems) {
                    setMediaItems(mediaItems);
                    const urls = mediaItems.flatMap((media) => media.imageItems.map((image) => image.url));
                    setImageUrls(urls);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setFetching(false);
        }
    }, [hotelCode, codeContext]);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { fetching, mediaItems, imageUrls, fetchData, abort };
}

useHotelMedia.propTypes = {
    hotelCode: PropTypes.string.isRequired,
    codeContext: PropTypes.string.isRequired,
};

export default useHotelMedia;
