import { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GetToken } from '../../utils/storage';
import axios from 'axios';
import * as config from '@config';

function useHotelCreatePNR(props) {
    const [submitting, setSubmitting] = useState(false);
    const abortControllerRef = useRef(null);

    const createHotelPNR = async ({ bookingKey, paymentData, leadGuests, rateKey, numOfRooms, amountAfterTax }) => {
        setSubmitting(true);
        if (!Array.isArray(leadGuests)) {
            throw new Error('leadGuests must be an array');
        }
        let payment = null;
        if (!!paymentData.cardNumber) {
            payment = paymentData;
        }
        const req = {
            payment: payment,
            leadGuests: leadGuests,
            rateKey: rateKey,
            bookingKey: bookingKey,
            numOfRooms: numOfRooms,
            amountAfterTax: amountAfterTax,
        };
        console.log(req);
        abortControllerRef.current = new AbortController();
        try {
            const res = await axios.post(`${config.apiURL}/sabre/hotel/pnr`, req, {
                signal: abortControllerRef.current.signal,
                headers: { Authorization: `Bearer ${GetToken()}` },
            });
            const data = res.data;
            console.log(data);

            return data;
        } catch (err) {
            throw err;
        } finally {
            setSubmitting(false);
        }
    };

    createHotelPNR.propTypes = {
        paymentData: PropTypes.object.isRequired,
        leadGuests: PropTypes.array.isRequired,
        rateKey: PropTypes.string.isRequired,
        bookingKey: PropTypes.string.isRequired,
        numOfRooms: PropTypes.number.isRequired,
        amountAfterTax: PropTypes.string.isRequired,
    };

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { submitting, createHotelPNR, abort };
}

useHotelCreatePNR.propTypes = {};

export default useHotelCreatePNR;
