import React from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/AccommsGrid.module.css';
import * as config from '@config';
import { useNavigate } from 'react-router';

function AccommsGrid({ accommodations = [] }) {
    return (
        <div className={styles.container}>
            {accommodations.map((item, index) => {
                return <AccommsGridItem key={index} accommodation={item} />;
            })}
        </div>
    );
}

AccommsGrid.propTypes = {
    accommodations: PropTypes.array.isRequired,
};

function AccommsGridItem({ accommodation = {} }) {
    const navigate = useNavigate();

    const gotoAccommodation = (id) => {
        navigate(id);
    };

    return (
        <div className={styles.grid_item_container}>
            <div className={styles.image_container}>
                <img
                    src={`${config.baseURL}/public/${accommodation.photos?.[0]?.filename}`}
                    onError={(e) => (e.currentTarget.src = `${config.baseURL}/public/empty_image.png`)}
                    onClick={() => gotoAccommodation(accommodation.id)}
                />
            </div>
            <div className={styles.trailing_container}>
                <div className={styles.title}>{accommodation.name}</div>
                <div className={styles.pricing_container}>
                    <div className={styles.prices_container}>
                        $A{accommodation.startingPrice}
                        <span> 起</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

AccommsGridItem.propTypes = {
    accommodation: PropTypes.object.isRequired,
};

export default AccommsGrid;
