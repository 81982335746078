import React from 'react';
import { Route, Routes } from 'react-router';
import HomePage from './HomePage';
import HotelResultsPage from './HotelResultsPage';
import HotelPage from './HotelPage';
import HotelCheckout from './HotelCheckout';
import OrdersPage from './OrdersPage';
import OrderPage from './OrderPage';

function Index() {
    return (
        <Routes path="/">
            <Route index element={<HomePage />} />
            <Route path="results/*" element={<HotelResultsPage />} />

            <Route path="orders/*">
                <Route index exact element={<OrdersPage />} />
                <Route path=":id" exact element={<OrderPage />} />
            </Route>

            <Route path="hotel/*">
                <Route index element={<div>Empty hotel</div>} />
                <Route path="book" element={<HotelCheckout />} />
                <Route path="*" element={<HotelPage />} />
            </Route>

            <Route path="/*" element={<div>Page not found</div>} />
        </Routes>
    );
}

export default Index;
