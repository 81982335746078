import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BackBar from '@components/BackBar';
import { useParams } from 'react-router';
import { Button, Input, Popconfirm, Space, Spin } from 'antd';
import useHotelOrderCancel from '@hooks/hotel/useHotelOrderCancel';
import useHotelOrderPut from '@hooks/hotel/useHotelOrderPut';
import useHotelOrderBooking from '@hooks/hotel/useHotelOrderBooking';
import HotelBookingOrderDescription from '../../../components/hotel/HotelBookingOrderDescription';
import { DevMode } from '@utils/utils';

function OrderPage() {
    const { id: orderID } = useParams();
    const {
        fetching,
        fetched,
        fetchOrderBooking,
        data: { order = {} },
        abort: abortFetch,
    } = useHotelOrderBooking();
    const { cancelling, handleOrderCancel, abort: abortCancel } = useHotelOrderCancel();
    const { submitting: refreshing, handleOrderPut, abort: abortRefresh } = useHotelOrderPut();

    useEffect(() => {
        fetchData();
        return () => {
            abortFetch();
            abortCancel();
            abortRefresh();
        };
    }, []);

    const fetchData = () => {
        console.log(orderID);
        fetchOrderBooking(orderID)
            .then((data) => {
                // console.log(data);
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            });
    };

    const handleCancel = () => {
        handleOrderCancel(orderID)
            .then((res) => {
                window.alert('Booking Cancelled');
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            })
            .finally(() => {
                handleOrderPut(orderID);
            });
    };

    return (
        <div>
            <BackBar />
            <Spin spinning={fetching || cancelling || refreshing} fullscreen />
            <br />

            <HotelBookingOrderDescription order={order} loading={fetching} />
            <br />
            <br />
            {fetched && (
                <Popconfirm
                    title="Cancel the booking"
                    description="Are you sure to cancel this booking?"
                    onConfirm={handleCancel}
                    onCancel={(e) => {
                        console.log(e);
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button danger>Cancel booking</Button>
                </Popconfirm>
            )}

            <br />
            <br />

            {DevMode() && (
                <Input.TextArea
                    value={JSON.stringify({ hotel: order.hotels, travelers: order.travelers }, null, 2)}
                    autoSize={{
                        minRows: 2,
                        maxRows: 20,
                    }}
                />
            )}
        </div>
    );
}

OrderPage.propTypes = {};

export default OrderPage;
