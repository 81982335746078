import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/Gallery.module.css';
import MyModal from './MyModal';
import PhotoSwiper from './PhotoSwiper';
import ImageSlides from './ImageSlides';

function Gallery({ urls = [], alts = [], alt = '' }) {
    const [open, setOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleOnClick = (index) => {
        // console.log(index);
        setActiveIndex(index);
        setOpen(true);
    };

    return (
        <div className={styles.container}>
            <div className={styles.grid_container}>
                {urls.map((item, index) => {
                    return (
                        <div key={index} className={styles.grid_item_container} onClick={() => handleOnClick(index)}>
                            <img src={item} alt={alts[index] ?? alt} />
                        </div>
                    );
                })}
            </div>
            <div className={styles.badge}>{urls?.length} 图片</div>
            <MyModal fullscreen={true} active={open} setActive={setOpen} closeIcon>
                {/* NOTE: In order to reset Swiper to let initIndex take effect */}
                {/* {open && <PhotoSwiper urls={urls} alt={alt} initIndex={activeIndex} />} */}
                {open && <ImageSlides urls={urls} initIndex={activeIndex} />}
            </MyModal>
        </div>
    );
}

Gallery.propTypes = {
    urls: PropTypes.arrayOf(PropTypes.string),
    alts: PropTypes.arrayOf(PropTypes.string),
    alt: PropTypes.string,
};

export default Gallery;
