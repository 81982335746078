import React, { useEffect, useRef, useState } from 'react';
import LodgingForm from '@components/hotel/LodgingForm';
import { useNavigate } from 'react-router';
import styles from '@styles/HotelHomePage.module.css';
import qs from 'qs';
import { Selector, SelectorParser } from '@utils/constants';
import * as config from '@config';
import { RemoveHotelAvailData } from '@utils/storage';
import TopHeaderBg from '@assets/hotel_top_bg.png';
import { defaultData as defaultHotelAvailReq } from '@components/hotel/LodgingForm';

function HomePage() {
    const navigate = useNavigate();
    const [req, setReq] = useState(defaultHotelAvailReq);

    useEffect(() => {
        RemoveHotelAvailData();
    }, []);

    const handleSubmit = (formReq) => {
        formReq.timestamp = Date.now();
        const queryString = qs.stringify(formReq);
        navigate({
            pathname: 'results',
            search: `?${queryString}`,
        });
    };

    const Hightlights = () => {
        const ref = useRef();

        const handleOnClick = (item) => {
            // console.log(item);
            // ref.current.showModal();
            setReq((prev) => {
                return {
                    ...prev,
                    cityName: item.cityEnglishName,
                    cityCode: item.cityCode,
                    airportCode: item.airportCode,
                    countryCode: item.countryCode,
                };
            });
        };

        return (
            <section className={styles.highlights_container}>
                {[
                    {
                        selector: Selector.Sydney,
                        cityName: '悉尼',
                        cityEnglishName: 'Sydney',
                        cityCode: 'SYD',
                        theAirportName: '悉尼金斯福德机场',
                        countryName: '澳大利亚',
                        countryCode: 'AU',
                    },
                    {
                        selector: Selector.Melbourne,
                        cityName: '墨尔本',
                        cityEnglishName: 'Melbourne',
                        cityCode: 'MEL',
                        theAirportName: '墨尔本机场',
                        countryName: '澳大利亚',
                        countryCode: 'AU',
                    },
                    {
                        selector: Selector.Brisbane,
                        cityName: '布里斯班',
                        cityEnglishName: 'Brisbane',
                        cityCode: 'BNE',
                        theAirportName: '布里斯班机场',
                        countryName: '澳大利亚',
                        countryCode: 'AU',
                    },
                    {
                        selector: Selector.GoldCoast,
                        cityName: '黄金海岸',
                        cityEnglishName: 'Gold Coast',
                        cityCode: 'OOL',
                        theAirportName: '黄金海岸机场',
                        countryName: '澳大利亚',
                        countryCode: 'AU',
                    },
                    {
                        selector: Selector.Perth,
                        cityName: '珀斯',
                        cityEnglishName: 'Perth',
                        cityCode: 'PER',
                        theAirportName: '珀斯机场',
                        countryName: '澳大利亚',
                        countryCode: 'AU',
                    },
                    {
                        selector: Selector.Cairns,
                        cityName: '凯恩斯',
                        cityEnglishName: 'Cairns',
                        cityCode: 'CNS',
                        theAirportName: '凯恩斯机场',
                        countryName: '澳大利亚',
                        countryCode: 'AU',
                    },
                    {
                        selector: Selector.Adelaide,
                        cityName: '阿德莱德',
                        cityEnglishName: 'Adelaide',
                        cityCode: 'ADL',
                        theAirportName: '阿德莱德机场',
                        countryName: '澳大利亚',
                        countryCode: 'AU',
                    },
                    // { selector: Selector.Canberra },
                ].map((item, index) => {
                    return (
                        <div key={index} className={styles.highlight_container} onClick={() => handleOnClick(item)}>
                            <div
                                className={styles.image_container}
                                style={{
                                    backgroundImage: `url('${config.baseURL}/images/place_${item.selector}.webp')`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                            <div className={styles.caption}>{SelectorParser(item.selector)}</div>
                        </div>
                    );
                })}
                <dialog ref={ref}>
                    <div className={styles.dialog_content}>
                        <div className="">Coming soon...</div>
                        <button className="primary_btn slim_btn greyscale" onClick={() => ref.current.close()}>
                            Close
                        </button>
                    </div>
                </dialog>
            </section>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.top_bg_container}>
                <img src={TopHeaderBg} />
                <div className={styles.works_container}>
                    <div className={styles.paragraph}>酒店</div>
                </div>
            </div>
            <div className={styles.content_container}>
                <div className={styles.search_container}>
                    <LodgingForm onSubmit={handleSubmit} initShowHistory={true} initData={req} />
                </div>
                {/* <Hightlights /> */}
            </div>
        </div>
    );
}

export default HomePage;
