import React from 'react';
import styles from '@styles/AccommHomePage.module.css';
import SelectorsForm from '@components/SelectorsForm';
import { Loader } from 'semantic-ui-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AccommodationSelectorOptions } from '@utils/constants';
import { useSelector } from 'react-redux';
import { Selector, SelectorParser } from '@utils/constants';
import * as config from '@config';

function HomePage() {
    const navigate = useNavigate();
    const fetching = useSelector((state) => state.accommodations.fetching);
    const [searchParams, setSearchParams] = useSearchParams();

    const handleSubmit = () => {
        const params = new URLSearchParams(searchParams);
        navigate(`search?${params.toString()}`);
    };

    const Hightlights = () => {
        const handleOnClick = (item) => {
            console.log(item);
            const selector = item.selector;
            const params = new URLSearchParams(searchParams);
            params.set('selector', selector || '');
            setSearchParams(params);
            navigate(`search?${params.toString()}`);
        };

        return (
            <section className={styles.highlights_container}>
                {[
                    {
                        selector: Selector.Sydney,
                    },
                    {
                        selector: Selector.Melbourne,
                    },
                    {
                        selector: Selector.Brisbane,
                    },
                    {
                        selector: Selector.GoldCoast,
                    },
                    {
                        selector: Selector.Perth,
                    },
                    {
                        selector: Selector.Cairns,
                    },
                    {
                        selector: Selector.Adelaide,
                    },
                    { selector: Selector.Canberra },
                ].map((item, index) => {
                    return (
                        <div key={index} className={styles.highlight_container} onClick={() => handleOnClick(item)}>
                            <div
                                className={styles.image_container}
                                style={{
                                    backgroundImage: `url('${config.baseURL}/images/place_${item.selector}.webp')`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                            <div className={styles.caption}>{SelectorParser(item.selector)}</div>
                        </div>
                    );
                })}
            </section>
        );
    };

    return (
        <div className={styles.container}>
            <section className={`${styles.form_section}`}>
                <div className={styles.label_container}>住宿</div>
                <div className={styles.form_container}>
                    <SelectorsForm
                        tagOptions={AccommodationSelectorOptions}
                        option={AccommodationSelectorOptions}
                        handleSubmit={handleSubmit}
                    />
                </div>
            </section>
            <div>
                {/* <h3>推荐城市</h3> */}
                <Hightlights />
            </div>

            <Loader active={fetching} inline="centered">
                Loading
            </Loader>
        </div>
    );
}

export default HomePage;
