import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Icon,
    Table,
    TableBody,
    TableHeader,
    Popup,
    Button,
    Modal,
    ModalActions,
    ModalHeader,
    ModalContent,
} from 'semantic-ui-react';
import styles from '@styles/RoomsTable.module.css';
import { differenceInDays, parseISO } from 'date-fns';

// For multi-room booking, room has different index(roomIndex) starting form 1
function RoomsTable({ rateInfos = [], rooms = [], mediaItems = [], onBooking = () => {} }) {
    return (
        <div className={styles.container}>
            <Table celled>
                <TableHeader className={styles.table_header}>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Bed</Table.HeaderCell>
                        <Table.HeaderCell>Pricing</Table.HeaderCell>
                        <Table.HeaderCell>Nights</Table.HeaderCell>
                        <Table.HeaderCell>Cancel Penalties</Table.HeaderCell>
                        <Table.HeaderCell>Additional Info</Table.HeaderCell>
                        <Table.HeaderCell>Rooms</Table.HeaderCell>
                        <Table.HeaderCell>Total</Table.HeaderCell>
                        <Table.HeaderCell>Commission</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </TableHeader>
                <TableBody>
                    {rateInfos.map((rateInfo, index) => {
                        return <RoomsTableRow key={index} rateInfo={rateInfo} rooms={rooms} handleBook={onBooking} />;
                    })}
                </TableBody>
            </Table>
        </div>
    );
}

RoomsTable.propTypes = {
    rateInfos: PropTypes.array.isRequired,
    rooms: PropTypes.array.isRequired,
    mediaItems: PropTypes.array.isRequired,
    onBooking: PropTypes.func.isRequired,
};

export default RoomsTable;

const RoomsTableRow = ({ rateInfo = {}, rooms = [], handleBook = () => {} }) => {
    let selectedRooms = rooms.filter((room) => room.ratePlans.some((plan) => plan.rateKey == rateInfo.rateKey));
    const numOfRoom = selectedRooms.length;
    //Using JSON.stringify() with field exclusion
    function stringifyExcept(obj, excludeField) {
        const { [excludeField]: _, ...rest } = obj;
        return JSON.stringify(rest);
    }
    const distinctRooms = selectedRooms.filter(
        (obj, index, self) =>
            index === self.findIndex((t) => stringifyExcept(t, 'roomIndex') === stringifyExcept(obj, 'roomIndex'))
    );

    // console.log(selectedRooms);
    const Amenities = ({ amenities = [] }) => {
        const [open, setOpen] = useState(false);
        if (!amenities || amenities.length === 0) {
            return null;
        }
        return (
            <div className={styles.amenities}>
                <a onClick={() => setOpen(true)}>Amenities</a>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <Modal.Header>Amenities</Modal.Header>
                    <Modal.Content>
                        {amenities.map((amenity, index) => {
                            return <div key={index}>{amenity.description}</div>;
                        })}
                    </Modal.Content>
                    <ModalActions>
                        <Button className="primary_btn" onClick={() => setOpen(false)}>
                            关闭
                        </Button>
                    </ModalActions>
                </Modal>
            </div>
        );
    };

    return (
        <>
            {distinctRooms.map((room, index) => {
                const ratePlan = room.ratePlans.find((plan) => plan.rateKey == rateInfo.rateKey);
                const roomRate = ratePlan.convertedRateInfo;

                const roomDescription = () => {
                    return (
                        <>
                            <div className={styles.room_type}>{room.roomType}</div>
                            <div className={styles.room_view_description}>{room.roomViewDescription}</div>
                        </>
                    );
                };

                const roomCapacity = () => {
                    let capacity = room.capacity;
                    const max = room?.occupancy?.max;
                    if (max) {
                        capacity = max;
                    }
                    return (
                        <>
                            {[...Array(capacity)].map((_, index) => {
                                return <Icon key={index} name="user" />;
                            })}
                        </>
                    );
                };

                return (
                    <Table.Row key={index}>
                        <Table.Cell>{roomDescription()}</Table.Cell>
                        <Table.Cell>
                            {room.bedTypeOptions.bedTypes.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {item.count} {item.description}
                                    </div>
                                );
                            })}
                            <div>{roomCapacity()}</div>
                            {ratePlan.mealsIncluded?.breakFast && (
                                <div className={styles.meal_container}>Breakfast</div>
                            )}
                            {ratePlan.mealsIncluded?.lunch && <div className={styles.meal_container}>Lunch</div>}
                            {ratePlan.mealsIncluded?.dinner && <div className={styles.meal_container}>Dinner</div>}
                            <Amenities amenities={room.amenities} />
                        </Table.Cell>
                        <Table.Cell>
                            <PriceDetails roomRate={roomRate} />
                        </Table.Cell>
                        <Table.Cell>
                            {GetNightsBetweenDates(rateInfo.startDate, rateInfo.endDate)}{' '}
                            {GetNightsBetweenDates(rateInfo.startDate, rateInfo.endDate) > 1 ? 'nights' : 'night'}
                        </Table.Cell>
                        <Table.Cell>
                            <CancelPenalties roomRate={roomRate} />
                        </Table.Cell>
                        <Table.Cell>
                            <AdditionalInfo roomRate={roomRate} />
                        </Table.Cell>
                        <Table.Cell>
                            {numOfRoom} {numOfRoom > 1 ? 'rooms' : 'room'}
                        </Table.Cell>
                        <Table.Cell>
                            {rateInfo.currencyCode} {rateInfo.amountAfterTax}
                        </Table.Cell>
                        <Table.Cell>
                            {rateInfo.currencyCode} {rateInfo.commission2}
                        </Table.Cell>
                        <Table.Cell>
                            <Button className="primary_btn" compact onClick={() => handleBook(rateInfo.rateKey)}>
                                Book
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                );
            })}
        </>
    );
};

RoomsTableRow.propTypes = {
    rateInfo: PropTypes.object.isRequired,
    rooms: PropTypes.array.isRequired,
    handleBook: PropTypes.func.isRequired,
};

const PriceDetails = ({ roomRate = {} }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <div>
                {roomRate.currencyCode} {roomRate.averageNightlyRate}/night
            </div>
            <div>
                <a onClick={() => setOpen(true)}>Details</a>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <Modal.Header>Price Details</Modal.Header>
                    <Modal.Content>
                        <Table celled>
                            <TableHeader>
                                <Table.Row>
                                    <Table.HeaderCell>Before Tax</Table.HeaderCell>
                                    <Table.HeaderCell>After Tax</Table.HeaderCell>
                                    <Table.HeaderCell>Taxes</Table.HeaderCell>
                                    <Table.HeaderCell>Fees</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <TableBody>
                                <Table.Row>
                                    <Table.Cell>
                                        <div>
                                            {roomRate.currencyCode} {roomRate.amountBeforeTax}
                                        </div>
                                        <div>
                                            {roomRate.currencyCode} {roomRate.averageNightlyRateBeforeTax}/night
                                        </div>
                                    </Table.Cell>

                                    <Table.Cell>
                                        <div>
                                            {roomRate.currencyCode} {roomRate.amountAfterTax}
                                        </div>
                                        <div>
                                            {roomRate.currencyCode} {roomRate.averageNightlyRate}/night
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {roomRate.taxes && `${roomRate.taxes.currencyCode} ${roomRate.taxes.amount}`}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {roomRate.fees && `${roomRate.fees.currencyCode} ${roomRate.fees.amount}`}
                                    </Table.Cell>
                                </Table.Row>
                            </TableBody>
                        </Table>
                    </Modal.Content>
                    <ModalActions>
                        <Button className="primary_btn" onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </ModalActions>
                </Modal>
            </div>
        </>
    );
};

PriceDetails.propTypes = {
    roomRate: PropTypes.object.isRequired,
};

const AdditionalInfo = ({ roomRate = {} }) => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <a onClick={() => setOpen(true)}>View</a>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Modal.Header>Additional Info</Modal.Header>
                <Modal.Content>
                    {roomRate.additionalDetails && (
                        <ol style={{ paddingLeft: '16px' }}>
                            {roomRate.additionalDetails.map((item, index) => {
                                return <li key={index}>{item.text}</li>;
                            })}
                        </ol>
                    )}
                </Modal.Content>
                <ModalActions>
                    <Button className="primary_btn" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </ModalActions>
            </Modal>
        </div>
    );
};

AdditionalInfo.propTypes = {
    roomRate: PropTypes.object.isRequired,
};

const CancelPenalties = ({ roomRate = {} }) => {
    const cancelPenalties = roomRate.cancelPenalties || [];
    const [open, setOpen] = useState(false);
    return (
        <>
            {cancelPenalties?.map((item, index) => {
                if (item.refundable) {
                    return (
                        <div key={index}>
                            <div>
                                <a onClick={() => setOpen(true)}>{item.deadline.text}</a>{' '}
                                {item.penaltyDescription.text && (
                                    <Popup
                                        content={item.penaltyDescription.text}
                                        trigger={<Icon name="info circle" />}
                                    />
                                )}
                            </div>
                            <div></div>
                        </div>
                    );
                } else {
                    return <div key={index}>Non-refundable</div>;
                }
            })}
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalHeader>Cancel Penalties</ModalHeader>
                <ModalContent>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{roomRate.cancelPenaltiesText}</p>
                </ModalContent>
                <ModalActions>
                    <Button className="primary_btn" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

CancelPenalties.propTypes = {
    // cancelPenalties: PropTypes.array.isRequired,
    roomRate: PropTypes.object.isRequired,
};

export function GetNightsBetweenDates(startDateString, endDateString) {
    // Parse the date strings into Date objects
    const startDate = parseISO(startDateString);
    const endDate = parseISO(endDateString);

    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        return '';
    }

    // Calculate the difference in days
    const nights = differenceInDays(endDate, startDate);

    return nights;
}
