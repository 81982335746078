import React from 'react';

import HotelOrdersTable from '@components/hotel/HotelOrdersTable';

function OrdersPage(props) {
    return (
        <div>
            <h3>Hotel Orders </h3>
            <HotelOrdersTable />
        </div>
    );
}

OrdersPage.propTypes = {};

export default OrdersPage;
